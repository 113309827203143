import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Section, SectionPageTitle, SectionDescription } from "../components/Section"
import SearchBar from "../components/SearchBar"
import StarRating from "../components/StarRating"
import BlueVector from "../images/blue-pattern.jpg"

const HeroBackground = styled.div`    
    display: flex;
    align-items: center;
    height: 100%;
    &:after, &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
        top:0;
        left:0;
        clip-path: ellipse(calc(100% + 120px) 100% at 50% 0);
        @media (min-width: 768px) {
            clip-path: ellipse(calc(100% - 15px) calc(100% + 180px) at 0% 50%);
        }
        @media (min-width: 992px) {
		    clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 0% 50%);
        }
	}
    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`
const ReviewGrid = styled.div`
    position: relative;
    @media (min-width: 768px) {
        display: grid;
        align-items:start;
        grid-template-columns: 1.2fr 2fr;
    }
    @media (min-width: 992px) {
        grid-template-columns: 1fr 2fr;
    }
`

const ReviewGridLeft = styled.div`
    position: relative;    
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));
        
    padding: 40px 15px 60px 15px;
    @media (min-width: 768px) {
        position: sticky;
        height: calc(100vh - 64px);
        top: 64px;
        padding: 40px 60px 120px 15px;
    }
    @media (min-width: 992px) {
        padding: 40px 60px 120px 30px;
       
    }
    @media (min-width: 1200px) {
        padding: 40px 60px 120px 30px;
        height: calc(100vh - 80px);
        top: 80px;
    }
    @media (min-width: 1600px) {
        padding: 60px 60px 190px 30px;
    }
    @media (min-width: 1650px) {
        padding: 60px 60px 190px calc(50vw - 810px);
    }
`
const ReviewGridRight = styled.div`
    position: relative;
    padding: 40px 15px;
    @media (min-width: 992px) {    
        padding: 40px 15px 120px 15px;
    }
    @media (min-width: 992px) {
        padding: 40px 30px 120px 30px;  
    }
    @media (min-width: 1200px) {
        padding: 40px 30px 120px 30px;  
    }
    @media (min-width: 1600px) {
        padding: 60px 30px 160px 30px;  
    }
    @media (min-width: 1650px) {
        padding: 60px calc(50vw - 810px) 160px 30px;  
    }
`

const HeroCaption = styled.div`
    width:100%;
    position: relative;
    z-index: 1;
    @media (max-width: 767.98px) {
        h1, p{
            text-align:center;
        }
    }
    @media (min-width: 768px) {
        max-width:375px;       
    }
`
const HeroSearch = styled.div`
    @media (min-width:992px){
        min-height: 78px;
        margin-top:-78px;
        position: sticky;
        bottom:0;
        left:0;
        z-index: 1;
    }
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        @media (min-width:992px){
            margin-left:0;
            bottom:15px;
        }
        @media (min-width:1600px){
            bottom:30px;
        }
    }
    .container{
        @media (max-width:991.98px){
            padding:0;
        }
    }

`
const ListMedia = styled.div`
    margin:0;
`
const ListMediaItem = styled.div`
    border-bottom:1px solid #ccc;
    margin-bottom:30px;
    padding-bottom:20px;
    @media (min-width: 1600px) {
        margin-bottom:40px;
        padding-bottom:30px;
    }
`
const Media = styled.div`
    display: flex;
    flex-wrap:wrap;
    margin:0 -10px;
    flex-direction:column;
    @media (min-width: 992px) {
        flex-direction: row;
    }
`
const MediaFigure = styled.div`
    position: relative;
    padding:0 10px;
    @media(max-width:991.98px){
        display:flex;
        align-items:center;
        margin-bottom:20px;
    }    
    .gatsby-image-wrapper{
        max-width: 70px;
        height:54px;
        width: 100%;
        border-radius:15px;
        overflow:hidden;
        img{
            border-radius:15px;
            overflow:hidden;
        }
    }
    .review-post{
        display:flex;
        margin:0;
        @media(min-width:992px){
            display:none;
        }        
        .post-date{
            line-height:20px;
        }
    }
`
const MediaBody = styled.div`
    position: relative;
    padding:0 10px;
    flex: 1;

    h3 {
        font-size: 24px;
        line-height: 1.5;
        font-weight: 600;
        margin-top: -8px;
        margin-bottom: 0;
    }
    p{
        font-size:18px;
        line-height:28px; 
        font-weight:500;
        font-style:italic;
        color:#000;
        margin-bottom:0;
        + p{
            margin-top:15px;
        }
        @media(min-width:1600px){
            font-size:20px;
            line-height:30px; 
        }
    }
    .review-post{
        @media(max-width:991.98px){
            display:none;
        }
    }
`
const ReviewPosted = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -9px;
    flex-direction:column;
    @media(min-width:992px){
        flex-direction:row;
    }
`
const ReviewRate = styled.div`
    padding:0 9px;
`
const PostedDate = styled.div`
    padding:0 9px;
    color:#999;
    font-size:14px;
    line-height:40px;
    font-weight:500;
`

const ReviewPage = ({ location, data }) => {
    

    const reviewData = data.allContentfulReview.edges
    return (
    <Layout location={location}>
        <Seo
            title={data.allContentfulPageData.edges[0].node.metaTitle}
            description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
        />
        <Section pt="0" pb="0" bgColor="#fff" className="section-reviews">            
            <ReviewGrid>
                <ReviewGridLeft>              
                    <HeroBackground bgAfter={`url(${BlueVector})`}>
                        <HeroCaption>
                            <SectionPageTitle color="#fff" mb="0">REVIEWS</SectionPageTitle>
                            <SectionDescription color="#fff" fontStyle="italic">
                                <p>We appreciate all of our dealer and customer partnerships. Our company is committed to providing the best craftsmanship in the industry. </p>
                            </SectionDescription>                            
                        </HeroCaption>
                    </HeroBackground>
                </ReviewGridLeft>
                <ReviewGridRight>
                    <ListMedia>
                        {reviewData.map((item, i) =>
                            <ListMediaItem key={i}>
                                <Media className="media">
                                    <MediaFigure className="media-figure">
                                        <ReviewPosted className="review-post">
                                            <ReviewRate>
                                                <StarRating name="rating1" ragingId1={`${i}-1`} ragingId2={`${i}-2`} ragingId3={`${i}-3`} ragingId4={`${i}-4`} ragingId5={`${i}-5`} reviewStar={item.node.star} />
                                            </ReviewRate>
                                            <PostedDate className="post-date">{item.node.createdAt}</PostedDate>
                                        </ReviewPosted>
                                    </MediaFigure>
                                    <MediaBody className="media-body">
                                        <h3>{item.node.reviewer}</h3>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.node.reviewText.childMarkdownRemark.html,
                                          }}
                                        />
                                        <ReviewPosted className="review-post">
                                            <ReviewRate>
                                                <StarRating name="rating1" ragingId1={`${i}-1`} ragingId2={`${i}-2`} ragingId3={`${i}-3`} ragingId4={`${i}-4`} ragingId5={`${i}-5`} reviewStar={item.node.star} />
                                            </ReviewRate>
                                            <PostedDate className="post-date">{ item.node.createdAt }</PostedDate>
                                        </ReviewPosted>
                                    </MediaBody>
                                </Media>
                            </ListMediaItem>
                        )}
                    </ListMedia>
                </ReviewGridRight>
            </ReviewGrid>
            <HeroSearch>
                <div className="container"><SearchBar /></div>
            </HeroSearch>
        </Section>
    </Layout>
  )
}

export default ReviewPage

export const pageQuery = graphql`
  query ReviewPageQuery{
    allContentfulPageData(filter: {page: {eq: "Reviews"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulReview(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          reviewer
          star
          createdAt(formatString: "DD MMMM YYYY")
          reviewText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`