import React from "react"
import "./rating.scss"

const StarRating = (props) => {
    const {ragingId1, ragingId2, ragingId3, ragingId4, ragingId5, name, size, reviewStar } = props;
    console.log(reviewStar)
    return (
        <div className="stars">
            <input type="radio" id={ragingId1} name={name} />
            <input type="radio" id={ragingId2} name={name} />
            <input type="radio" id={ragingId3} name={name} />
            <input type="radio" id={ragingId4} name={name} />
            <input type="radio" id={ragingId5} name={name} />
            <label htmlFor={ragingId1}  className={`${reviewStar > 0 && reviewStar <= 5 ? 'checked' : `label-${size}`}`}>1 star</label>
            <label htmlFor={ragingId2}  className={`${reviewStar > 1 && reviewStar <= 5 ? 'checked' : `label-${size}`}`}>2 stars</label>
            <label htmlFor={ragingId3}  className={`${reviewStar > 2 && reviewStar <= 5 ? 'checked' : `label-${size}`}`}>3 stars</label>
            <label htmlFor={ragingId4}  className={`${reviewStar > 3 && reviewStar <= 5 ? 'checked' : `label-${size}`}`}>4 stars</label>
            <label htmlFor={ragingId5}  className={`${reviewStar === 5 ? 'checked' : `label-${size}`}`}>5 stars</label>
        </div>
    )
}

export default StarRating
